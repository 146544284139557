@font-face {
  font-family: "Aeonik";
  font-weight: 300;
  src: local("Light"),
    url(../public/assets/fonts/Aeonik-Light.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik";
  font-weight: 200;
  src: local("Thin"),
    url(../public/assets/fonts/Aeonik-Thin.otf) format("opentype");
}

@font-face {
  font-family: "Aeonik";
  font-weight: 400;
  src: local("Regular"),
    url(../public/assets/fonts/Aeonik-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik";
  font-weight: 500;
  src: local("Medium"),
    url(../public/assets/fonts/Aeonik-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Aeonik";
  font-weight: 700;
  src: local("Bold"),
    url(../public/assets/fonts/Aeonik-Bold.otf) format("opentype");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  /* -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #000 !important; */
  -webkit-background-clip: text;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}